@font-face {
    font-family: Tiempos;
    src: url('tiempos-regular.woff');
    font-weight: 300
}
@font-face {
    font-family: Tiempos;
    src: url('tiempos-regular.woff');
    font-weight: 400
}
@font-face {
    font-family: Tiempos;
    src: url('tiempos-medium.woff');
    font-weight: 700
}
@font-face {
    font-family: Tiempos;
    src: url('tiempos-medium.woff');
    font-weight: 900
}
